<template>
  <div>
    <h1>Boodschappen lijst</h1>
    <div v-if="error" style="color:red">{{error}}</div>
    <div class="searchBar">
      <input type="text" name="" id="" v-model="item" placeholder="Voeg product toe">
      <button @click="addItem">+</button>
      <div class="ideaDrop" v-show="item && item !== inFitem && filteredItems.length > 0" >
        <div v-for="fitem in filteredItems" :key="fitem" @click="this.item = fitem ; inFitem = fitem">
          {{fitem}}
        </div>
      </div>
    </div>
    <div class="itemList" v-if="itemList.attributes">
      <div @click="saveItemState(j)" v-for="(j, index) in itemList.attributes.items.data" :key="j" class="item" :class="j.attributes.checked ? 'checked':''">
        <input type="checkbox" name="" id="" v-model="j.attributes.checked" >
        <div>
          {{j.attributes.text}}
        </div>
        <button @click="removeItem(j,index)">x</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: 'App',
  data(){
    return {
      item: "",
      itemList: {},
      error: "",
      allItems: {},
      inFitem: ""
    }
  },
  async mounted(){
    this.updateItems()
  },
  computed:{
    filteredItems: function(){
      let results = []
      for (let i = 0; i < this.allItems.length; i++) {
        const elem = this.allItems[i].attributes.text;
        if(elem.includes(this.item)){
          results.push(elem)
        }
      }
      return results
    }
  },
  methods:{
    updateItems: async function(){
      try {
        const response = await axios.get(process.env.VUE_APP_URL+'/api/boodschappens?populate=*')
        this.itemList = response.data.data[0]
      } catch (error) {
        this.error = error;
      }
      try {
        const response = await axios.get(process.env.VUE_APP_URL+'/api/items?populate=*')
        this.allItems = response.data.data
      } catch (error) {
        this.error = error;
      }
    },
    addItem: async function(){
      const check = this.allItems.filter((elem) => elem.attributes.text == this.item)
      let response = {};
      if(check.length > 0){
        response = await axios.get(process.env.VUE_APP_URL+'/api/items/' + check[0].id)
      }else{
        response = await axios.post(process.env.VUE_APP_URL+'/api/items',{
          data: {
            text: this.item
          }
        })
      }
      await axios.put(process.env.VUE_APP_URL+'/api/boodschappens/'+this.itemList.id+'/?populate=*', {
        data: {
          items: {
            connect: [response.data.data.id]
          }
        }
      })
      this.item = ""
      this.updateItems()
    },
    saveItemState: async function(i){
      await axios.put(process.env.VUE_APP_URL+'/api/items/'+i.id, {
        data: {
          checked: !i.attributes.checked
        }
      })
      this.updateItems();
    },
    removeItem: async function(i, index){
      this.itemList.attributes.items.data.splice(index, 1)
      await axios.put(process.env.VUE_APP_URL+'/api/boodschappens/'+this.itemList.id+'/?populate=*', {
        data: {
          items: this.itemList.attributes.items.data
        }
      })
      
      await axios.put(process.env.VUE_APP_URL+'/api/items/'+i.id, {
        data: {
          checked: false
        }
      })
      this.updateItems()
    }
  }
}
</script>

<style>
body{
  margin:0;
  padding:0;
  background: #121212;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #BB86FC;
  margin-top: 40px;
}
.item{
  display: flex;
  border: 1px solid #282828;
  padding: 5px 20px ;
  width: 90vw;
  justify-content: space-between;
  margin: 0 10px;
  box-shadow: 0 0 5px 0 #282828;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  align-items: center;
  background: #282828;
  color:white;
}
.item button{
  font-size: 30px;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  color:red;
}
.itemList{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  gap: 5px;
}
.searchBar{
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin: 0 auto;
}
.searchBar > input{
  font-size: 20px;
  border: none;
  outline: none;
  background:#282828;
  color:white;
  font-weight: 700;
  padding: 10px;
  border-radius: 10px 0 0 10px;
  border: 1px solid white;
  border-right: none;
}
.searchBar > button{
  border-radius: 0 10px 10px 0;
  outline: none;
  border: 1px solid white;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: #BB86FC;
  padding: 0 20px;
  cursor: pointer;
  background: #282828;
}
.ideaDrop{
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background:#282828;
  box-shadow: 0 0 5px 0 #282828;
  max-height: 30vh;
  overflow: auto;
}
.ideaDrop > div{
  padding: 10px;
  margin: 5px;
  box-shadow: 0 0 2px 0 grey;
  z-index: -1;
}
.checked{
  background: rgb(27, 27, 27);
  color: rgb(49, 49, 49);
  border-color: rgb(27, 27, 27);
  box-shadow: inset 0 0 5px 0 black;
}
</style>
